console.log("layout loaded");
function openMobileMenu()
{
	var $mobilemenu = $("#mobilemenu");
	var $sectionhs = $(".section.homeslider");
	if($sectionhs.length > 0) {
		$mobilemenu.outerHeight($sectionhs.outerHeight());
	}
	$mobilemenu.fadeIn(200);
	$("#btnmobilemenuopen").fadeOut(200);
	$("#btnmobilemenuclose").fadeTo(200, 1);
}
function closeMobileMenu()
{
	$("#mobilemenu").fadeOut(200);
	$("#btnmobilemenuopen").fadeTo(200, 1);
	$("#btnmobilemenuclose").fadeOut(200);
}

$(document).ready(function() {
	$("#btnmobilemenuopen").click(openMobileMenu);
	$("#btnmobilemenuclose").click(closeMobileMenu);

	$(".bl-slider .images").slick({
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: false,
		pauseOnHover: false
	});
});

function showModalBG()
{
	$("#modalbg").fadeTo(500, 0.7);
}
function hideModalBG()
{
	$("#modalbg").fadeOut();
}
