function showModalBG() {
  $("#modalbg").fadeTo(500, 0.7);
}
function hideModalBG() {
  $("#modalbg").fadeOut();
}

$(document).ready(function () {
  $(".bl-aktuelles").each(function () {
    var $bl = $(this);
    var $popup = $bl.find(".aktuellpopup");

    function showPopup(aid) {
      $popup.find(".content").hide();
      $popup.find(".content[data-aid=" + aid + "]").show();
      showModalBG();
      $popup.fadeIn();
      $("#modalbg").one("click", function () {
        hidePopup();
      });
    }

    function hidePopup() {
      $("#modalbg").off("click");
      hideModalBG();
      $popup.fadeOut();
    }

    $bl.find(".openpopup").click(function () {
      var aid = $(this).data("aid");
      showPopup(aid);
    });

    $popup.find(".closebutton").click(hidePopup);

    function switchContent($newcontent) {
      $popup.find(".content:visible").fadeOut(250, function () {
        $newcontent.fadeIn(250);
      });
    }

    $popup.find(".prevbtn").click(function () {
      var $prevcontent = $popup.find(".content:visible").prev(".content");
      if ($prevcontent.length == 0) {
        $prevcontent = $popup.find(".content:last");
      }
      switchContent($prevcontent);
    });
    $popup.find(".nextbtn").click(function () {
      var $nextcontent = $popup.find(".content:visible").next(".content");
      if ($nextcontent.length == 0) {
        $nextcontent = $popup.find(".content:first");
      }
      switchContent($nextcontent);
    });
  });
});
