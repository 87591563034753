$(document).ready(function() {
    function scrollToCompany(companyId){
        $(".bl-companydetail").hide()
        let companyInfo = $('.bl-companydetail[data-companyId="' + companyId + '"]')
        companyInfo.fadeIn(1000)
        $("html, body").animate({
            scrollTop: companyInfo.offset().top - 80
        }, 1000);
    }

    // Register Callback on company tile click
	$('*[data-action="showCompanyInfo"]').click(function(){
        scrollToCompany($(this).attr("data-companyId"))
    });

    // Scroll directly to company if a param is set
    let companyId = new URLSearchParams(location.search).get("company")
    if(companyId){
        scrollToCompany(companyId)
    }
});